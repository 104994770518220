import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Text } from '../components/ui';
import Layout from '../components/Layout';
import Logo from '../components/Logo';
import Search from '../components/Search';

const SearchPageTemplate = ({ pageContext: { companies } }) => (
  <Layout altLogo={true}>
    <Helmet title="Search | Impact Score" defer={false} />
    <Logo />
    <Search items={companies} />
    <Text>
      <Link to="/company-not-found">
        Can't find the company you’re looking for?
        <br /> Click here
      </Link>
    </Text>
  </Layout>
);

export default SearchPageTemplate;
