import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import * as JsSearch from 'js-search';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  visuallyHidden,
} from '../styles/utilities';

const StyledSearch = styled.form`
  margin: 20px 0;
`;

const StyledSearchLabel = styled.label``;

const StyledSearchText = styled.span`
  ${visuallyHidden()};
`;

const StyledSearchInput = styled.input`
  padding: 10px 20px 10px 50px;
  height: 50px;
  width: 100%;
  color: ${brandColours.secondary};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.regular};
  ${fontSize(16)};
  background: url('/images/search-icon.svg') no-repeat 20px 50%/18px 18px
    ${standardColours.white};
  border: 1px solid ${brandColours.secondary};
  border-radius: 22px;
`;

const StyledSearchList = styled.ol`
  margin: 10px 0;
  padding: 10px;
  max-height: 300px;
  overflow: auto;
  ${fontSize(15)};
  background-color: ${standardColours.white};
  border: 1px solid ${brandColours.secondary};
  border-radius: 22px;
`;

const StyledSearchItem = styled.li`
  margin: 8px 0;
`;

const StyledSearchLink = styled(Link)`
  display: block;
  padding: 8px 15px;
  color: ${brandColours.secondary};
  line-height: 1.5;
  border-radius: 22px;

  &:hover {
    background-color: ${brandColours.tertiary};
  }
`;

const Search = ({ items }) => {
  const [search, setSearch] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const dataToSearch = new JsSearch.Search('name');
    dataToSearch.addIndex('name');
    dataToSearch.addDocuments(items);
    setSearch(dataToSearch);
  }, [items]);

  const searchItems = e => {
    setResults(search.search(e.target.value));
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <StyledSearch onSubmit={e => handleSubmit(e)}>
      <StyledSearchLabel>
        <StyledSearchText>Search for company name</StyledSearchText>
        <StyledSearchInput
          type="text"
          placeholder="Search for company name"
          onChange={e => searchItems(e)}
        />
      </StyledSearchLabel>
      {results.length > 0 && (
        <StyledSearchList>
          {results.slice(0, 10).map(({ name }) => {
            const slug = name
              .toString()
              .toLowerCase()
              .replace(/\s+/g, '-')
              .replace(/[^\w-]+/g, '')
              .replace(/--+/g, '-')
              .replace(/^-+/, '')
              .replace(/-+$/, '');

            return (
              <StyledSearchItem key={slug}>
                <StyledSearchLink to={`/${slug}`}>{name}</StyledSearchLink>
              </StyledSearchItem>
            );
          })}
        </StyledSearchList>
      )}
    </StyledSearch>
  );
};

export default Search;
